const TokenKey = 'Admin-Token'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

export function checkWeComEnvironment() {
  return /wxwork/i.test(navigator.userAgent)
}

/**
 *
 * @returns work-wx-mobile手机端企业微信，work-wx-pcPC端企业微信， wx-mobile 手机端微信，wx-pc PC端微信，other其他非微信环境
 *
 */

export function envJudge() {
  const isMobile = window.navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  ) // 是否手机端
  const isWx = /micromessenger/i.test(navigator.userAgent) // 是否微信
  const isComWx = /wxwork/i.test(navigator.userAgent) // 是否企业微信

  if (isComWx && isMobile) {
    //手机端企业微信
    return 'work-wx-mobile'
  } else if (isComWx && !isMobile) {
    //PC端企业微信
    return 'work-wx-pc'
  } else if (isWx && isMobile) {
    // 手机端微信
    return 'wx-mobile'
  } else if (isWx && !isMobile) {
    // PC端微信
    return 'wx-pc'
  } else {
    // 非微信
    return 'other'
  }
}

export function checkWechatEnvironment() {
  return /MicroMessenger/i.test(navigator.userAgent) && !checkWeComEnvironment()
}

export function getWeComCodeByRedirection() {
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_SUITE_ID}&redirect_uri=${process.env.VUE_APP_BASE_URL}&response_type=code&scope=snsapi_base#wechat_redirect`
}

export function getWechatCodeByRedirection() {
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcfa784c64b632e2a&redirect_uri=${process.env.VUE_APP_BASE_URL}&response_type=code&scope=snsapi_base#wechat_redirect`
}
