import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from '@/router'
import ElementPlus from 'element-plus'
import Translate from '@/utils/translate'
import 'element-plus/dist/index.css'

// 1. 引入你需要的组件
import {
  Button,
  NavBar,
  Search,
  Icon,
  Empty,
  Checkbox,
  CheckboxGroup,
  List,
  Loading,
  Tag,
  Picker,
  Popup,
  Cell,
  Col,
  Row,
  DatePicker,
  Collapse,
  CollapseItem,
  Tabbar,
  TabbarItem,
  PullRefresh,
  Popover,
  Step,
  Steps,
  ActionBar,
  ActionBarButton,
  Form,
  Field,
  CellGroup,
  RadioGroup,
  Radio,
  Badge,
  Uploader,
  Tab,
  Tabs,
  ActionSheet,
} from 'vant'
import 'vant/lib/index.css'

import 'animate.css'

// 引入全局样式
import '@/assets/css/index.scss'

import * as dd from 'dingtalk-jsapi'

import App from './App.vue'
import '@/assets/iconfont.css'

import setJsonProxy from '@/utils/jsonProxy'
import debounce from '@/utils/debounce'
import vConsole from 'vconsole'
//这个库会在桌面端自动将 mouse 事件转换成对应的 touch 事件
import '@vant/touch-emulator'
const app = createApp(App)
if (process.env.NODE_ENV !== 'production') {
  const VC = new vConsole()
  app.use(VC)
}
//全局挂在方法
app.config.globalProperties.$setJson = setJsonProxy
app.config.globalProperties.$dd = dd
app.config.globalProperties.$debounce = debounce
app.config.globalProperties.$t = new Translate(localStorage.getItem('_lang') || 'CN', '1', '1').$t

const piniaStore = createPinia()
app.use(piniaStore)

app.use(Button)
app.use(NavBar)

app.use(Search)
app.use(Icon)
app.use(Empty)
app.use(Checkbox)
app.use(CheckboxGroup)
app.use(List)
app.use(Loading)
app.use(Tag)
app.use(Picker)
app.use(Popup)
app.use(Cell)
app.use(Col)
app.use(Row)
app.use(DatePicker)
app.use(Collapse)
app.use(CollapseItem)
app.use(Tabbar)
app.use(TabbarItem)
app.use(PullRefresh)
app.use(Popover)
app.use(Step)
app.use(Steps)
app.use(ActionBar)
app.use(ActionBarButton)
app.use(Form)
app.use(Field)
app.use(CellGroup)
app.use(Radio)
app.use(RadioGroup)
app.use(Badge)
app.use(Uploader)
app.use(Tab)
app.use(Tabs)
app.use(ActionSheet)

app.use(router)
app.use(ElementPlus)
app.mount('#app')
