import axios from 'axios'
import { getToken, getWechatCodeByRedirection, removeToken } from '@/utils/auth'
import { showNotify } from 'vant'
import router from '@/router'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken()

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }

    return config
  },
  (error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.code === '2007') {
      // https://developers.weixin.qq.com/doc/offiaccount/Getting_Started/Global_Return_Code.html
      // 如果 code 过期（40029），则重新构造授权链接获取新的 code
      getWechatCodeByRedirection()
      return Promise.reject('error')
    }

    //TOEKN_NULL("5001", "token为空"),
    //TOKEN_INVALID("5002", "token无效"),
    //TOKEN_EXPIRE("5003", "token过期"),
    //TOKEN_DATA_ERROR("5004", "token中参数获取失败"),
    if (response.data.code === '5001') {
      // 这里必须把查询参数带上，Login 组件要用到
      router.push(`/login${window.location.search}`)
      return Promise.reject('token为空')
    }

    if (['5002', '5003', '5004'].includes(response.data.code)) {
      showNotify({ type: 'warning', message: '您的登录信息已过期，请关闭页面重新登录' })
      Cookies.remove('access-token')
      removeToken()
    }

    return response.data
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
