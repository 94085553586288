/**
 * Note: 路由配置项
 *
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 导航栏展示的 标题
    showTab: true                   // 是否显示底部 tab (默认 false)
    customNav: true                  // 是否需要自定义导航栏 (默认 false)
    layout: true                  // 是否需要统一layout布局 (默认 false)
    pageKey: string                  // src/locales/labels.js 根据 pageKey 查找需要上传翻译的标签
  }
 */

const routes = [
  {
    path: '/login',
    meta: { title: '登录', noToken: true },
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
  },
  {
    path: '/phone-binding',
    component: () => import(/* webpackChunkName: "PhoneBinding" */ '@/views/PhoneBinding/index.vue'),
    meta: { title: '集合派', noToken: true },
    children: [
      {
        path: '',
        redirect: '/phone-binding/step-one',
      },
      {
        path: 'step-one',
        component: () => import(/* webpackChunkName: "PhoneBindingStepOne" */ '@/views/PhoneBinding/StepOne/index.vue'),
        meta: { title: '集合派', noToken: true },
      },
      {
        path: 'step-two',
        component: () => import(/* webpackChunkName: "PhoneBindingStepTwo" */ '@/views/PhoneBinding/StepTwo/index.vue'),
        meta: { title: '集合派', noToken: true },
      },
      {
        path: 'step-three',
        component: () =>
          import(/* webpackChunkName: "PhoneBindingStepThree" */ '@/views/PhoneBinding/StepThree/index.vue'),
        meta: { title: '集合派', noToken: true },
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "BasicLayout" */ '@/layouts/index.vue'),
    children: [
      {
        path: '',
        redirect: '/transportOrder',
      },
      //采购单
      {
        path: '/purchaseOrder',
        children: [
          {
            path: '',
            redirect: '/purchaseOrder/index',
          },
          {
            path: 'index',
            component: () => import(/* webpackChunkName: "purchaseOrder" */ '@/views/purchaseOrder/index.vue'),
            meta: {
              title: '采购单列表',
              showTab: true,
            },
          },
          {
            path: 'details',
            component: () => import(/* webpackChunkName: "purchaseOrderDetails" */ '@/views/purchaseOrder/details.vue'),
            meta: {
              title: '采购单详情',
              noCache: true,
            },
          },
          {
            path: 'plan-details',
            component: () =>
              import(/* webpackChunkName: "purchaseOrderPlanDetails" */ '@/views/purchaseOrder/planDetails.vue'),
            meta: {
              title: '采购单计划详情',
            },
          },
          {
            path: 'creation',
            component: () =>
              import(/* webpackChunkName: "purchaseOrderCreation" */ '@/views/purchaseOrder/uploadOrder.vue'),
            meta: {
              title: '采购单导入',
            },
          },
        ],
      },
      //集拼单
      {
        path: '/transportOrder',
        children: [
          {
            path: '',
            redirect: '/transportOrder/index',
          },
          {
            path: 'index',
            component: () => import(/* webpackChunkName: "transportOrder" */ '@/views/transportOrder/index.vue'),
            meta: {
              title: '集拼单列表',
              showTab: true,
              pageKey: 'transportOrderList',
            },
          },
          {
            path: 'details',
            component: () =>
              import(/* webpackChunkName: "transportOrderDetails" */ '@/views/transportOrder/details.vue'),
            meta: {
              noCache: true,
              title: '物流轨迹',
            },
          },
          {
            path: 'creation',
            component: () =>
              import(/* webpackChunkName: "transportOrderCreation" */ '@/views/transportOrder/uploadOrder.vue'),
            meta: {
              title: '专线单导入',
            },
          },
          {
            path: 'creation-carton',
            component: () =>
              import(
                /* webpackChunkName: "transportOrderCreationCarton" */ '@/views/transportOrder/creationCarton.vue'
              ),
            meta: {
              title: '专线单创建',
            },
          },
        ],
      },
      //提单
      {
        path: '/billOfLading',
        children: [
          {
            path: '',
            redirect: '/billOfLading/index',
          },
          {
            path: 'index',
            component: () => import(/* webpackChunkName: "billOfLading" */ '@/views/billOfLading/index.vue'),
            meta: {
              title: '提单列表',
              showTab: true,
              pageKey: 'billOfLadingList',
            },
          },
          {
            path: 'details',
            component: () => import(/* webpackChunkName: "billOfLadingDetails" */ '@/views/billOfLading/details.vue'),
            meta: {
              title: '提单详情',
              noCache: true,
              pageKey: 'billOfLadingDetail',
            },
          },
          {
            path: 'creation',
            component: () =>
              import(/* webpackChunkName: "billOfLadingCreation" */ '@/views/billOfLading/uploadOrder.vue'),
            meta: {
              title: '提单导入',
              noCache: true,
            },
          },
          {
            path: 'locus',
            component: () => import(/* webpackChunkName: "billOfLadingLocus" */ '@/views/billOfLading/locus.vue'),
            meta: {
              title: '箱货状态跟踪',
              noCache: true,
              pageKey: 'billOfLadingLocus',
            },
          },
        ],
      },
      //物流轨迹
      {
        path: 'trace-shadow',
        component: () => import(/* webpackChunkName: "traceShadow" */ '@/views/traceShadow/index.vue'),
        meta: {
          noCache: true,
          title: '物流跟踪',
          pageKey: 'traceShadow',
        },
      },
      // 用户成员设置
      {
        path: '/groupMemberSetting',
        component: () => import(/* webpackChunkName: "groupMemberSetting" */ '@/views/groupMemberSetting/index.vue'),
        meta: {
          title: '用户成员设置',
          noCache: true,
          pageKey: 'groupMemberSetting',
        },
      },
      // 货箱列表
      {
        path: '/carton',
        children: [
          {
            path: 'index',
            component: () => import(/* webpackChunkName: "carton" */ '@/views/carton/index.vue'),
            meta: {
              title: '货箱列表',
            },
          },
          {
            path: 'details',
            component: () => import(/* webpackChunkName: "cartonDetails" */ '@/views/carton/details.vue'),
            meta: {
              title: '货箱详情',
              noCache: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
  },
]

export default routes
