import SlsTracker from '@aliyun-sls/web-track-browser'
const logstore = process.env.VUE_APP_TRACK_LOGSTORE || 'web-log'
const host = process.env.VUE_APP_TRACK_HOST || 'cn-hangzhou.log.aliyuncs.com'
const opts = {
  host: host, // 所在地域的服务入口。例如cn-hangzhou.log.aliyuncs.com
  project: process.env.VUE_APP_TRACK_PROJECT || 'uat-zmetaport', // Project名称。
  logstore: logstore, // Logstore名称。
  time: 10, // 发送日志的时间间隔，默认是10秒。
  count: 10, // 发送日志的数量大小，默认是10条。
  topic: 'weblog', // 自定义日志主题。
  source: 'im',
  tags: {
    frontend: 'im',
  },
}

export const logTracker = new SlsTracker(opts)
