import axios from 'axios'
import { getToken, getWeComCodeByRedirection, removeToken } from '@/utils/auth'
import { showNotify } from 'vant'
import Cookies from 'js-cookie'
import errorCode from '@/utils/errorCode'
import router from '@/router'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_API_BASE,
  // 超时
  timeout: 20000,
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    const token = getToken()

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
  },
  (error) => {
    // console.log(error)
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']

    if (res.data.msg === '40029') {
      // https://developer.work.weixin.qq.com/document/path/90313
      // 如果 code 过期，重新构造授权链接获取新的 code
      getWeComCodeByRedirection()
      return Promise.reject(res.data)
    }

    if (code === 401) {
      // 后端需要细化一下返回的状态码，没有 access_token 和 access_token 过期不能都用 401 来表示
      if (res.data.msg === '登录状态已过期') {
        showNotify({ type: 'warning', message: '您的登录信息已过期，请关闭页面重新登录' })
        Cookies.remove('access-token')
        removeToken()
      } else {
        // 这里必须把查询参数带上，Login 组件要用到
        const query = window.location.search
          ? `${window.location.search}&redirect=${location.pathname}`
          : `?redirect=${location.pathname}`
        router.push(`/login${query}`)
      }

      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
      showNotify({ type: 'warning', message: msg })
      return Promise.reject(new Error(msg))
      // 临时处理1001报错，需求不希望看到提示
    } else if (code !== 200 && code !== '0000' && code !== '1001') {
      showNotify({ type: 'danger', message: msg })
      return Promise.reject(res.data)
    } else {
      return res.data
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
