import { showNotify } from 'vant'
import wechatRequest from '@/utils/wechatRequest'
import request from '@/utils/request'

export async function login(wechatCode) {
  try {
    const { code, message, data } = await wechatRequest({
      url: '/wechatServer/wx/wxLogin',
      method: 'post',
      data: { code: wechatCode, systemCode: 'JHP' },
    })

    if (code === '0000') {
      return data
    } else {
      showNotify({ type: 'warning', message })
    }
  } catch (error) {
    console.log(error)
  }
}

/**
 * 获取用户信息
 */
export function getInfo() {
  return request({
    url: '/system/user/wechat/getInfo',
    method: 'get',
  })
}
