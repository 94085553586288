import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import Translate from '@/utils/translate'
import { logTracker } from '@/utils/tracker'
import user from '@/store/user'
import { getToken } from '@/utils/auth'

/**
 * 列表页面 'list-box' 滚动
 * @param {*} path
 * @param {*} isBack true 的时候取缓存， false的时候存
 */
const handleScrollTop = (path, isBack) => {
  const listBox = document.getElementsByClassName('list-box')[0]
  if (listBox && isBack) {
    listBox.scrollTop = sessionStorage.getItem(path)
  } else if (listBox) {
    sessionStorage.setItem(path, listBox.scrollTop)
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    handleScrollTop(to.path, true)
  },
})

router.beforeEach((to, from) => {
  const token = getToken()
  const query = window.location.search
    ? `${window.location.search}&redirect=${location.pathname}`
    : `?redirect=${location.pathname}`
  if (!token && to.path !== '/login' && !to.meta?.noToken) {
    return `/login${query}`
  }
  //判断是否有标题
  if (to.meta.title) {
    document.title = to.meta.title
  }
  //列表到详情，缓存列表页高度
  handleScrollTop(from.path)
})

const handleTracker = (to, nickName, phonenumber, userId) => {
  //埋点
  logTracker.send({
    eventName: '页面访问',
    path: to.path,
    pageName: to.meta?.title,
    userName: nickName,
    phonenumber,
    userId,
    baseUrl: process.env.VUE_APP_BASE_URL,
    env: /wxwork/i.test(navigator.userAgent.toLowerCase()) ? '企业微信' : '微信公众号',
  })
}

router.afterEach(async (to, from) => {
  const token = getToken()
  console.log('to', to.path, token)
  if (to.path !== '/login' && token) {
    //获取翻译
    new Translate().getTranslate(localStorage.getItem('_lang') || 'CN')

    //用户信息
    const { userInfo } = user()
    if (userInfo) {
      const { nickName, phonenumber, userId } = userInfo
      handleTracker(to, nickName, phonenumber, userId)
    } else {
      user()
        .getUserInfo()
        .then((res) => {
          const { nickName, phonenumber, userId } = user()?.userInfo
          handleTracker(to, nickName, phonenumber, userId)
        })
    }
  }
})

export default router
