import axios from 'axios'
import SlsTracker from '@aliyun-sls/web-track-browser'

const tracker = new SlsTracker({
  host: 'cn-hangzhou.log.aliyuncs.com',
  project: process.env.VUE_APP_TRACK_PROJECT || 'uat-zmetaport', // Project名称。,
  logstore: 'translate-log',
})

let instance = null

/** Translation */
class T {
  /**
   * @param {string} lang 要翻译成的语言类型 CN-中文 EN-英文 AR-阿语
   * @param {string} businessType 业务类型
   * @param {string} appId 区分应用 1.IM端 2.小程序 3.PC
   */
  constructor(lang = 'CN', businessType, appId) {
    this.langData = {}
    this.lang = lang || 'CN'
    this.businessType = businessType
    this.appId = appId
  }
  /** 统一去掉 url 后面的 / */
  static formatPageUrl() {
    let pageUrl = `${location.origin}${location.pathname}`
    return pageUrl.endsWith('/') ? pageUrl.slice(0, pageUrl.length - 1) : pageUrl
  }

  /**
   * 翻译
   * @param {string} label 初始 label
   * @return {string} 翻译之后的 label
   */
  $t(label) {
    const pageUrl = T.formatPageUrl()
    instance.update(label)
    if (instance.langData[pageUrl] && instance.langData[pageUrl][label]) {
      return instance.langData[pageUrl][label]
    } else {
      //TODO 重试
      return label
    }
  }

  /**
   * 切换语言
   * @param {string} lang 要翻译成的语言类型 CN-中文 EN-英文 AR-阿语
   */
  setLang(lang) {
    if (this.lang === lang) return
    this.langData = {}
    this.lang = lang
    this.getTranslate()
  }

  /**
   * 上报
   * @param {string} label
   */
  update(label) {
    tracker.send({
      targetParams: label,
      languageType: this.lang,
      businessType: this.businessType,
      appId: this.appId,
      pageUrl: T.formatPageUrl(),
    })
  }

  /**
   * 获取翻译
   */
  async getTranslate() {
    const pageUrl = T.formatPageUrl()
    const params = {
      languageType: this.lang,
      businessType: this.businessType,
      appId: this.appId,
      pageUrl,
    }
    try {
      const res = await axios.get(`${process.env.VUE_APP_API_BASE}/translation/multilingual/translation`, {
        params,
      })

      const { data } = res
      if (data && data.code === 200 && data.data) {
        this.langData[pageUrl] = data.data.resultData
      } else {
        console.log('getTranslate, err', data)
      }
    } catch (error) {
      console.log('getTranslate', error)
    }
  }
}

const Translate = (function () {
  /**
   * @param {string} lang 要翻译成的语言类型 CN-中文 EN-英文 AR-阿语
   * @param {string} businessType 区分业务类型，或许是端
   * @param {string} appId 区分应用
   */
  return function (lang, businessType, appId) {
    if (!instance) {
      instance = new T(lang, businessType, appId)
    }
    return instance
  }
})()

export default Translate
