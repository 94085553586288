import { defineStore } from 'pinia'
import { getInfo } from '@/api/user'

const user = defineStore('user', {
  state: () => {
    return {
      userInfo: '',
      chatId: '',
      roleCount: 0,
    }
  },
  getters: {
    getInfo() {
      return this.userInfo
    },
    getChatId() {
      return this.chatId
    },
  },
  actions: {
    async getUserInfo(data) {
      const res = await getInfo()
      if (res && res.user) {
        this.userInfo = res.user
      }
    },
    setChatId(data) {
      this.chatId = data
    },
    setRoleCount(data) {
      this.roleCount = data
    },
  },
})
export default user
